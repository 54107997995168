import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import PatientService from "../../services/patient";
import AlertMessage from "../../components/alert-message";
import {
  UserOutlined,
  RightOutlined,
  DownOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  DownloadOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import "./profile.scss";
import moment from "moment";
import PatientTreatmentIndex from "../patient_treatment";
import ManIcon from "../../components/icons/male-icon";
import FemaleIcon from "../../components/icons/female-icon";
import OtherGenderIcon from "../../components/icons/other-gender-icon";
import Input from "react-phone-number-input/input";
import toastMessage from "../../components/toast-message";
import { downloadXLSFile } from "../../services/download";
import Filter from "images/filter.png";
import { Tooltip, TooltipWrapper } from "react-tooltip";
import PatientSelect from "./patient-select";

const PatientProfile = (props) => {
  let navigate = useHistory();
  const [error, setError] = useState("");

  const { id } = useParams();
  const [scrollId, _] = useState(window.location.search?.split("?")[1]?.split("=")[1]);
  const [sameDateQuery, setSameDateQuery] = useState(
    window.location.search?.split("?")[1]?.split("=")[1] == "true" ? true : false,
  );
  const [currentTab, setCurrentTab] = useState("patient");
  const [reload, setReload] = useState(false);
  const [supervisorActive, setSupervisorActive] = useState(props.location?.query?.expendSupervisor || false);
  const [patientId, setPatientId] = useState(id);
  const [patient, setPatient] = useState({});
  const [treatment, setTreatment] = useState({});
  const [supervisor, setSupervisor] = useState({});
  const [supervisorPatientId, setSuperVisorPatientId] = useState(undefined);
  const [patients, setPatients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    PatientService.show(patientId).then(
      (response) => {
        if (response.data.patient && response.data.patient.role == "patient") {
          setPatient(response.data.patient);
          setTreatment(response.data.patient.treatment_pathway);
          if (response.data.patient.supervisor) {
            setSupervisor(response.data.patient.supervisor);
          } else {
            setSupervisor({});
          }
          setAppointments(response.data.patient.appointments);
        } else if (response.data.patient && response.data.patient.role != "patient") {
          setSupervisorActive(!supervisorActive);
          setSupervisor(response.data.patient);
          setSuperVisorPatientId(response.data.patient?.patient?.id);
        }
      },
      (error) => {
        toastMessage.error(error.meta.message);
        setPatient({});
        setSupervisor({});
      },
    );
  }, [patientId, reload]);

  const showSupervisor = (e) => {
    if (Object.keys(supervisor).length > 0) {
      setSupervisorActive(!supervisorActive);
    } else {
      navigate.push({
        pathname: `/patients/${patientId}/edit`,
        query: { expendSupervisor: true },
      });
    }
  };

  const onChangePatient = (e) => {
    setPatientId(e.value);
    navigate.push({ pathname: `/patients/${e.value}/profile` });
    window.location.reload();
  };

  const goToSearch = () => {
    navigate.push({ pathname: "/patients" });
  };

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.patient.confirm_delete"))) {
      const res = await PatientService.destroy(id);
      navigate.push({
        pathname: "/patients",
        state: { name: "actionSuccess", message: res.data.message },
      });
    }
  };

  const deactivePatient = async () => {
    if (window.confirm(I18n.t("form.patient.confirm_disactive"))) {
      try {
        const res = await PatientService.deactive(patient.id);
        toastMessage.success(res.data.message);
        window.location.reload();
      } catch (e) {
        toastMessage.error(e.meta.message);
        setReload(!reload);
      }
    }
  };

  if (props.location?.query?.expendSupervisor && supervisorActive) {
    const view = document.getElementById("supervisorView");
    view?.scrollIntoView();
  }

  const serviceProviderName = (provider) => {
    if (provider) {
      if (provider.is_internal) {
        return provider.first_name + " " + provider.last_name;
      } else {
        if (
          (provider.first_name && provider.first_name.length > 0) ||
          (provider.last_name && provider.last_name.length > 0)
        ) {
          return provider.first_name + " " + provider.last_name;
        } else {
          return <HomeOutlined />;
        }
      }
    } else {
      return "";
    }
  };

  const toggleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    if (props.location?.state?.appointmentId) {
      const view = document.getElementById("appointment-index");
      view?.scrollIntoView();
    }
  }, [props.location?.state?.appointmentId]);

  const handleDownloadData = async () => {
    try {
      await downloadXLSFile(`/patients/${patientId}.xls`, {}, "xls");
    } catch (e) {}
  };

  const handleFilter = (type) => {
    if (filterType == "prom") {
      setFilterType("");
    } else {
      setFilterType(type);
    }
    const view = document.getElementById("appointment-index");
    view?.scrollIntoView();
  };


  return (
    patient && (
      <div className="row mt-5 position-relative">
        <Tooltip id="tooltip-edit-patient" content={I18n.t("tooltip.edit_patient")} />
        <Tooltip id="tooltip-download-patient" content={I18n.t("tooltip.download_patient")} />
        <Tooltip id="tooltip-prom-filter" content={I18n.t("tooltip.prom_filter")} />
        {error.length > 0 && <AlertMessage variant="danger" message={error} />}

        <div id="patient-switch">
          <div className={"admin-view"}>
            <PatientSelect
              onChangePatient={onChangePatient}
              patients={patients}
              selectedPatientName={patient?.last_name + " " + patient?.first_name}
              selectedPatientId={patient?.id}
            />
            <SearchOutlined className="mr-1" onClick={goToSearch} />
          </div>
        </div>
        <div id="profile-tab-patient">
          <div onClick={() => setCurrentTab("patient")} className={currentTab == "patient" ? "active" : ""}>
            Patient
          </div>
          <div className="profile-action">
            <Link
              to={{
                pathname: `/patients/${patient.id || supervisorPatientId}/edit`,
                query: {
                  expendSupervisor: patient.role == "patient" ? false : true,
                },
              }}
              className="btn btn-primary"
            >
              <TooltipWrapper tooltipId="tooltip-edit-patient">
                <EditOutlined />
              </TooltipWrapper>
            </Link>
            {patient?.id && (
              <a onClick={() => handleDownloadData()} className="btn btn-success">
                <TooltipWrapper tooltipId="tooltip-download-patient">
                  <DownloadOutlined />
                </TooltipWrapper>
              </a>
            )}
            {patient?.id && (
              <a onClick={() => handleFilter("prom")} className="btn btn-info">
                <TooltipWrapper tooltipId="tooltip-prom-filter">
                  <img src={Filter} width="25" height="25" onClick={() => handleFilter("prom")} />
                </TooltipWrapper>
              </a>
            )}
            {patient?.id && patient.appointments.length == 0 && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(patient.id);
                }}
                className="btn btn-danger delete-patient"
              >
                <DeleteOutlined />
              </a>
            )}
          </div>
        </div>
        {currentTab == "patient" && (
          <div className="col-md-12" id="patient-profile">
            {(Object.keys(patient).length > 0 || Object.keys(supervisor).length > 0) && (
              <div className="header">
                <span className="name-holder">
                  {patient.gender == "male" && <ManIcon color="#48F0C6" />}
                  {patient.gender == "female" && <FemaleIcon color="#48F0C6" />}
                  {patient.gender == "other" && <OtherGenderIcon color="#48F0C6" />}
                  {`${patient.last_name || supervisor.last_name} ${patient.first_name || supervisor.first_name}`}
                </span>
                {patient.role == "patient" && (
                  <div className="patient-status">
                    <div className="form-check form-switch">
                      <label className="form-check-label" htmlFor="activeUser">
                        {patient.active
                          ? I18n.t("activerecord.attributes.patient.active")
                          : I18n.t("activerecord.attributes.patient.inactive")}
                        {!patient.active && <span className="deactive-time">{patient.deactive_time}</span>}
                      </label>
                      <input
                        onChange={deactivePatient}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="activeUser"
                        defaultChecked={patient.active}
                      />
                    </div>
                    {patient.is_complete_pathway && (
                      <div className="complete-status">
                        <div className={`circle ${patient.is_complete_pathway ? "green" : ""}`}></div>
                        <span className="label">
                          {patient.is_complete_pathway
                            ? I18n.t("form.patient.treatment_complete")
                            : I18n.t("form.patient.treatment_open")}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {Object.keys(patient).length > 0 && patient.role != "patient" && (
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.patient.patient_id")}</div>
                    <div onClick={() => setPatientId(patient.patient.id)} className="content green change-patient">
                      {patient.patient.patient_id}
                    </div>
                  </div>
                )}
              </div>
            )}
            {Object.keys(patient).length > 0 && (
              <div className="body mt-3">
                {patient.role == "patient" && (
                  <>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.birthday")}</div>
                      <div className="content green">
                        {patient.birthday ? moment(patient.birthday).format("DD.MM.YYYY") : ""}
                      </div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.gender")}</div>
                      <div className="content text-capitalize">
                        {I18n.t(`enumerize.defaults.gender.${patient.gender}`)}
                      </div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.email")}</div>
                      <div className="content">{patient.email}</div>
                    </div>
                    <div className="field with-custom-status">
                      <div className="label">{I18n.t("activerecord.attributes.patient.phone_number")}</div>
                      <div className="content phone-number">
                        {patient.phone_number && (
                          <Input
                            disabled
                            withCountryCallingCode
                            international
                            onChange={() => console.log(patient.phone_number)}
                            value={"+" + patient.phone_number}
                          />
                        )}
                      </div>
                    </div>

                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.street_number")}</div>
                      <div className="content">{patient.street_number}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.house_number")}</div>
                      <div className="content">{patient.house_number}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.zip")}</div>
                      <div className="content">{patient.zip}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.place")}</div>
                      <div className="content">{patient.place}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.insurance_number")}</div>
                      <div className="content">{patient.insurance_number}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.kiss_id")}</div>
                      <div className="content">{patient.kiss_id}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.communication_path")}</div>
                      <div className="content text-uppercase">
                        {I18n.t(`enumerize.defaults.communication_path.${patient.communication_path}`)}
                      </div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.language")}</div>
                      <div className="content">{patient.patient_language?.name}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("activerecord.models.treatment_pathway")}</div>
                      <div className="content">{treatment ? treatment.name_de : ""}</div>
                    </div>
                    <div className="field">
                      <div className="label">{I18n.t("form.service_provider.name_in")}</div>
                      <div className="content">{serviceProviderName(patient.provider)}</div>
                    </div>

                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.career_changer")}</div>
                      <div className="content">
                        {I18n.t(
                          `enumerize.defaults.yes_no.${
                            patient.career_changer == "no" || !patient.career_changer ? false : true
                          }`,
                        )}
                      </div>
                    </div>

                    <div className="field">
                      <div className="label">{I18n.t("activerecord.attributes.patient.patient_info")}</div>
                      <div className="content">{patient.patient_info}</div>
                    </div>
                  </>
                )}
                {patient.role == "patient" && (
                  <div className="med-btn float-end fs-5" onClick={showSupervisor}>
                    <span>
                      {Object.keys(supervisor).length > 0
                        ? I18n.t("form.patient.show_supervisor")
                        : I18n.t("form.patient.new_supervisor")}
                    </span>
                    {!supervisorActive && <RightOutlined />}
                    {supervisorActive && <DownOutlined />}
                  </div>
                )}
              </div>
            )}

            {supervisorActive && Object.keys(supervisor).length > 0 && (
              <div id="supervisorView" className="body supervisor">
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.first_name")}</div>
                  <div className="content green">{supervisor.last_name}</div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.last_name")}</div>
                  <div className="content green">{supervisor.first_name}</div>
                </div>
                {/* <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.birthday")}</div>
                  <div className="content green">
                    {supervisor.birthday ? moment(supervisor.birthday).format("DD.MM.YYYY") : ""}
                  </div>
                </div> */}
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.gender")}</div>
                  <div className="content text-capitalize">
                    {I18n.t(`enumerize.defaults.gender.${supervisor.gender}`)}
                  </div>
                </div>
                {/* <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.street_number")}</div>
                  <div className="content">{supervisor.street_number}</div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.house_number")}</div>
                  <div className="content">{supervisor.house_number}</div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.zip")}</div>
                  <div className="content">{supervisor.zip}</div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.place")}</div>
                  <div className="content">{supervisor.place}</div>
                </div> */}
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.email")}</div>
                  <div className="content">{supervisor.email}</div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.phone_number")}</div>
                  <div className="content phone-number">
                    {supervisor.phone_number && (
                      <Input
                        disabled
                        withCountryCallingCode
                        international
                        onChange={() => console.log(supervisor.phone_number)}
                        value={"+" + supervisor.phone_number}
                      />
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.communication_path")}</div>
                  <div className="content text-uppercase">
                    {I18n.t(`enumerize.defaults.communication_path.${supervisor.communication_path}`)}
                  </div>
                </div>
                <div className="field">
                  <div className="label">{I18n.t("activerecord.attributes.patient.language")}</div>
                  <div className="content">{supervisor.patient_language?.name}</div>
                </div>
              </div>
            )}
          </div>
        )}

        <div id="appointment-index" className="mb-5">
          {patient && patient.role == "patient" && (
            <PatientTreatmentIndex
              filterType={filterType}
              scrollId={scrollId}
              sameDateQuery={sameDateQuery}
              scrollTo={props.location?.state?.appointmentId || undefined}
              newProviderId={props.location?.state?.id || undefined}
              reloadPatient={() => toggleReload()}
              patient={patient}
              appointments={appointments}
              patientId={patient.id}
            />
          )}
        </div>
      </div>
    )
  );
};

export default PatientProfile;
